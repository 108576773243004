
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import $ from 'jquery';
import trawelmart from "images/trawelmart.png"

export interface PageThanksProps {
  className?: string;
}

const PageThanks: FC<PageThanksProps> = ({ className = "" }) => {

  useEffect(() => {
    setTimeout(() => {
      (window as any).location = '/';
    }, 5000);
  }, []);

  $(function () {
    var currentStep = 1;
    var totalSteps = 3;

    $(".step7" + currentStep).show();
    $("#prevButton").hide(); // Initially hide the Previous button in Step 1

    // Set the initial labels for the buttons
    $("#prevButton1").html("&larr;");
    $("#nextButton1").html("&rarr;");

    $("#nextButton1").click(function () {
      if (currentStep < totalSteps) {
        $(".step7" + currentStep).hide();
        currentStep++;
        $(".step7" + currentStep).show();

        // Update the label for the Next button based on the current step
        if (currentStep === 2) {
          $("#nextButton1").html("&rarr;");
        } else if (currentStep === 3) {
          $("#nextButton1").html("&rarr;"); // Checkmark symbol for final step
        }

        // Show the Previous button after moving to Step 2
        if (currentStep === 2) {
          $("#prevButton1").html("&larr;").show();
        }
      }
    });

    $("#prevButton1").click(function () {
      if (currentStep > 1) {
        $(".step7" + currentStep).hide();
        currentStep--;
        $(".step7" + currentStep).show();

        // Update the label for the Previous button based on the current step
        if (currentStep === 1) {
          $("#prevButton1").html("&larr;").hide();
        }

        // Update the label for the Next button based on the current step
        if (currentStep === 2) {
          $("#nextButton1").html("&rarr;");
        } else if (currentStep === 3) {
          $("#nextButton1").html("&rarr;");
        }
      }
    });
  });






  return (
    <div
      className={`font nc-PageAbout overflow-hidden relative font xl:pt-24 xl:pb-32 lg:pt-24 lg:pb-32 md:pt-24 md:pb-24 pt-16 pb-32 ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Travel Mart</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="font flex content-center items-center justify-center space-y-0  lg:space-y-0">
        {/*<SectionHero
          rightImg={rightImg}
          heading="THANK YOU!"
          description="Our travel advisor will get in touch with you shortly"
          btnText=""
          subHeading="From here, you can scroll down to explore the Trawel mart experience even more. Check out our story, vision, mission and more..."
  />*/}

        <div className=" ">

        </div>





        <div className="w-12/12">
          <div className=" center">
            <img className="w-64 text-center center" src={trawelmart} alt="" />
          </div>
          <div className="text-center fonts xl:text-2xl  xl:pt-5 lg:text-4xl md:text-2xl text-xl lg:pt-4 md:pt-3 pt-3 xl:px-0 lg:px-0  md:px-0 px-4">
            Thank you for your new booking. One of our representative will get in touch with you.
          </div>
          <div className=" content-center text-xl font text-center xl:pt-2 xl:px-0 lg:px-0  md:px-0 px-4 lg:pt-2 md:pt-2 pt-2">
            For More Information, Get In Touch With Us
          </div>
          <div className="flex content-center justify-center items-center xl:pt-3 lg:pt-3 md:pt-2 pt-2 text-sky-600 font font-medium xl:text-xl lg:text-xl md:text-xl text-lg px-12 xl:block lg:block md:block hidden sm:block">
            <span className="xl:pr-6">080 – 4642 7999  </span> | <span className="xl:pl-6">enquiry@trawelmart.com</span>
          </div>
          <div className="flex flex-wrap content-center justify-center items-center xl:pt-5 lg:pt-3 md:pt-2 pt-2 text-sky-600 font font-medium xl:text-xl lg:text-xl md:text-xl text-lg px-12 xl:hidden lg:hidden md:hidden block sm:hidden">
            <span className="xl:pr-6">080 – 4642 7999  </span>  <span className="xl:pl-6">enquiry@trawelmart.com</span>
          </div>
        </div>

      </div>

      {/* ======== Vision Value ======== 

      <div className="font container py-16 lg:py-6 space-y-16 lg:space-y-2">
        <div className="flex lg:flex-row flex-wrap lg:pb-12">
          <div className="lg:w-6/12">
            <span className="font-bold text-4xl">
              Our Story
            </span>
            <p className="font-medium text-base lg:pt-3">
              The company regarded as experts in international travel packages have developed tourism
              related business in National and international sectors. They recommend destinations and excel in designing
              tailor made tour packages for outbound tourists.
            </p>
            <p className="font-medium text-base lg:pt-3">
              They enhance the service by offering the best possible prices on a worldwide range of high-quality services.
              Insight expertise, personalizing travel experiences and personal service are the USPs of this company.
            </p>
          </div>
          <div className="lg:w-1/12">

          </div>
          <div className="lg:w-5/12 border rounded-lg shadow-md">

            <div className="btn ">
              <div className="step71">
                <div className="p-6">
                  <img src={quotation} alt="" className="w-10 h-10 lg:-mb-5" />
                  <span className="font text-2xl text-neutral-800 font-extrabold lg:-mt-12">
                    Our Vision
                  </span>
                  <p className="font text-base font-medium lg:pt-2">
                    To establish and maintain TRAWEL MART as the most highlighted travel agency.
                    A reliable and enthusiastic tour operator with superior customer service to provide extra value
                    added service to the customers.
                  </p>
                </div>
              </div>
              <div className="step72 " style={{ display: 'none' }}>
                <div className="p-6">
                  <img src={quotation} alt="" className="w-10 h-10 lg:-mb-5" />
                  <span className="font text-2xl text-neutral-800 font-extrabold lg:-mt-12">
                    Our Mission
                  </span>
                  <p className="font text-base font-medium lg:pt-2">
                    To continuously expand our customer base by being a proactive travel service company
                    and provide comprehensive range of travel services products. To be one of the foremost and innovative
                    travel company in the world.
                  </p>
                </div>
              </div>
              <div className="step73" style={{ display: 'none' }}>
                <div className="p-6">
                  <img src={quotation} alt="" className="w-10 h-10 lg:-mb-5" />
                  <span className="font text-2xl text-neutral-800 font-extrabold lg:-mt-12">
                    Founders Motto
                  </span>
                  <p className="font text-base font-medium lg:pt-2">
                    Going above and beyond to provide excellent, World class affordable travel with customer sservice and satisfaction as the key. To bring
                    in creative and innovativetour packages to  cater to customers from all walks of life.

                  </p>
                </div>
              </div>

              <div className="pt-4">

                <div className="flex flex-col lg:flex-row items-center">
                  <div className="lg:w-5/12">
                  </div>
                  <div className="lg:w-7/12 text-right items-center pb-6">
                    <button id="prevButton1" className="text-xl text-blue-800 font-bold rounded-lg px-4 bg-blue-100 mr-4"></button>
                    <button id="nextButton1" className="text-xl text-blue-800 font-bold rounded-lg px-4 bg-blue-100 mr-4"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-neutral-50 py-16">
        <div className="container py-16 lg:py-6 space-y-16 lg:space-y-2">
          <div className="grid lg:grid-cols-4 lg:gap-2 lg:px-24">
            <div className="text-center justify-center">
              <div className="justify-center text-center center">
              <img src={icon1} alt="" className="w-20 h-20" />
              </div>
              <span className="">
              2 Decades of Experience and Expertise
            </span>
            </div>

            <div className="text-center justify-center">
              <div className="justify-center text-center center">
              <img src={icon2} alt="" className="w-20 h-20" />
              </div>
              <span className="">
              2 Decades of Experience and Expertise
            </span>
            </div>
            
            <div className="text-center justify-center">
              <div className="justify-center text-center center">
              <img src={icon2} alt="" className="w-20 h-20" />
              </div>
              <span className="">
              2 Decades of Experience and Expertise
            </span>
            </div>


            <div className="text-center justify-center">
              <div className="justify-center text-center center">
              <img src={icon4} alt="" className="w-20 h-20" />
              </div>
              <span className="">
              2 Decades of Experience and Expertise
            </span>
            </div>

          </div>
        </div>
      </div>*/}

      {/* ======== Vision Value ENDS======== */}


    </div>
  );
};

export default PageThanks;
